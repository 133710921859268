<template>
  <div class="page-services animate__animated animate__fadeIn pt-4">
    <img
      :src="require('@/assets/banners/address-updates.png')"
      class="w-100 mb-4"
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <h5 class="fs-la">
      Select your Benefits
    </h5>
    <p>We're happy to help you update the benefits listed below. If your specific benefit isn't included you will need to update those updates directly.</p>
    <!-- Services -->
    <div class="row mb-4">
      <div id="row-service" class="col-12" v-for="(item, index) in services" :key="index" style="padding: 4px 2px">
        <CheckboxBig8
          :logo=null
          :description=item.name
          :informName=item.inform
          :value=item.value
          :subdescription=item.description
          :imgOnError="'form/assets/hmrc.svg'"
          :widthLogo="'70px'"
        />

      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { navigateTo } from "../router/index.js";
import { isMobile } from "../utils/isMobile";
import  { setRoute }  from "../router/setRoute.js";
import CheckboxBig8 from "../components/CheckboxBig8.vue";

// @ is an alias to /src

export default {
  name: "PageDWP",
  components: {CheckboxBig8},
  data() {
    return {
      services: [],
      isMobile,
      checkValue: false,
      isCanopy: false,
      errors: {
        termsChecked: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    openInfoTooltip() {
      this.$store.commit("setField", ["currentTooltip", "ActionSheetServices"]);
      const pushbar = new Pushbar({
        blur: true,
        overlay: true,
      });
      pushbar.open("action-sheet");
    },
    selectService(informName, value) {

      this.$store.commit("formStore/setField", [`${informName}`, !value]);
      this.fillServices();
    },
    currentServices() {
      return [
        { name: "Attendance Allowance", img: null, inform: "informAttendanceAllowance", value: this.informAttendanceAllowance, description:"" },
        { name: "Child Maintenance", img: null, inform: "informChildBenefit", value: this.informChildBenefit, description:"" },
        { name: "Disability Living Allowance (DLA)", img: null, inform: "informDisabilityLivingAllowance", value: this.informDisabilityLivingAllowance, description:"" },
        { name: "Pension Credits", img: null, inform: "informPensionCredits", value: this.informPensionCredits , description:""},
        { name: "Personal Independence Payment (PIP)", img: null, inform: "informPersonalIndependencePayment", value: this.informPersonalIndependencePayment, description:"" },
        { name: "State Pension", img: null, inform: "informStatePension", value: this.informStatePension , description:""},
      ];
    },
    fillServices() {
      const services = [];
      /*this.isCanopy = this.referrer.includes("anopy");*/
      this.currentServices().forEach((s) => {
        /*else if (this.isCanopy){
          if (!(s.name==="Council Tax" || s.name ==="Water")){
            if (!this.iWantAllServices){
              return;
            }
          }
        }else if (this.referrer==='cExtended'){
          if (s.name==="Council Tax" || s.name ==="Water" || s.name==="Energy"){
            return;
          }
        }*/
        services.push(s);
      });
      this.services = [...new Set(services)];
    },
    updateValue(itemName, value) {
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    showLabelServices() {
      return this.services.find((element) => element.value == true);
    },
    validateInputs() {
      if (!this.termsChecked) {
        this.errors.termsChecked = true;
      } else {
        this.errors.termsChecked = false;
      }
    },

    autoClickCheckbox(className) {
      document.querySelector(`.${className} input[type=checkbox]`).click();
    },


  },
  mounted() {

    this.fillServices(); //We show the boxes with the services that the user wishes to update

    this.$store.commit("setField", ["titleNavbar", "DWP"]);
    this.$store.commit("setField", ["step2", true]);

    const validatePage = () => {
      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));

      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "email",
      "moveToPostCode",
      "moveFromPostCode",
      "baseURL",
      "urlBucket",
      "informAttendanceAllowance",
      "informChildBenefit",
      "informDisabilityLivingAllowance",
      "informPensionCredits",
      "informPersonalIndependencePayment",
      "informStatePension",
      "informDWP",
      "informChildBenefit",
      "informPension",
      "informCharities",
      "informLottery",
      "informEnergy",
      "informWaterSewerage",
      "informCouncilTax",
      "informTVLicense",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informOpticians",
      "termsChecked",
      "iWantAllServices"

    ]),
    ...mapGetters("formStore", ["state"]),
  },
};
</script>
<style lang="scss">
.beta{
  background-color: red;
  color: whitesmoke;
  border-radius: 15%;
  padding: 3%;
  margin-right: -7px;
  margin-top: -40px;
}
.fs-beta{
  font-size: 6px;
}
.page-services {
  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    transition: all 0.1s ease-in-out;

    img {
      width: 60%;
    }
  }

  .selected {
    background-color: #1cb0f6;
    color: white !important;
  }

  @media screen and (min-width: 1300px) and (max-width: 1980px) {
    .beta{
      background-color: red;
      color: whitesmoke;
      border-radius: 15%;
      padding: 3%;
      margin-top: -70px;
    }
    .fs-beta{
      font-size: 11px;
    }
    .label-pages.hmrc {
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
