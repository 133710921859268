<template>
  <div class="d-flex mb-2 w-100 option" @click="updateValue()">
    <div
      :class="`boxes w-95 ps-2 ${value && !subdescription ? 'checked' : ''}`"
      style="margin-right: 0.7rem; width:95%"
    >
      <div style="width:25%">
        <img v-if="logo!==null"
             :src="isLocalImg ? logo : `${urlBucket}/${logo}`"
             @error="
          (e) => {
            e.target.src = `${urlBucket}/${imgOnError}`;
            hideSpinner(`${informName}${description.replace(/ /g, '-')}`);
          }
        "
             :width="widthLogo"
             class="mx-2"
             style="display: none; max-height: 55px"
             @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner(`${informName}${description.replace(/ /g, '-')}`);
          }
        "
        />
      </div>

      <div
        :id="`${informName}${description.replace(/ /g, '-')}`"
        class="text-center mx-2"
      >
        <img v-if="logo!==null" src="/assets/img/spinner-loading.gif" width="30px" alt="" />
      </div>
      <!-- height="40px" -->
      <div style="width:75%">
        <p style="font-weight: 600;font-size:18px" class="mb-0 fs-md">{{ description }}</p>
        <p class="mb-0 fs-xs">
          {{subdescription}}
        </p>
      </div>
    </div>
    <div
      :class="`boxes justify-content-center ${value ? 'checked' : ''}`"
      style="width: 80px"
    >
      <img
        v-if="value"
        :src="require('@/assets/check.png')"
        width="41px"
        height="33px"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Checkbox",
  props: {
    logo: [String],
    description: [String],
    subdescription: [String],
    informName: [String],
    value: [Boolean],
    widthLogo: {
      type: String,
      default: "40px",
    },
    isLocalImg: {
      type: Boolean,
      default: false,
    },
    imgOnError: {
      type: String,
      default: "",
    },
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    updateValue() {
      let contr =!this.value;
      if ("informWaterSewerage"=== this.informName){
        this.$store.commit("formStore/setField", ["informEnergy", contr]);
      }
      this.$store.commit("formStore/setField", [this.informName, contr]);
      this.value= contr;
    },
  },
  mounted() {

  console.log(this.logo);
  },
  updated(){
    if (this.informName==="informNHS"){
      this.value = this.informNHS;
    }
    if (this.informName==="informDVLA"){
      this.value = this.informDVLA;
    }
    if (this.informName==="informElectoralRoll"){
      this.value = this.informElectoralRoll;
    }
    if (this.informName==="informHMRC"){
      this.value = this.informHMRC;
    }
    if (this.informName==="informWaterSewerage"){
      this.value = this.informWaterSewerage;
    }
    if (this.informName==="informCouncilTax"){
      this.value = this.informCouncilTax;
    }
    if (this.informName==="informTVLicense"){
      this.value = this.informTVLicense;
    }
  },

  computed: {
    ...mapState("formStore", [
      "referrer",
      "email",
      "moveToPostCode",
      "moveFromPostCode",
      "baseURL",
      "urlBucket",
      "informNHS",
      "informDVLA",
      "informElectoralRoll",
      "informHMRC",
      "informDWP",
      "informEnergy",
      "informWaterSewerage",
      "informCouncilTax",
      "informTVLicense"
    ]),
  },

};
</script>

<style lang="scss">
.option {
  cursor: pointer;
  height: 80px;
}

.boxes {
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;
}

.checked {
  border: 1px solid #2ec989;
  background-color: #1e9d68;
  color: white;
}
</style>
